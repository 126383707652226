import React, { useState, useEffect } from "react";
import { getRecommendedAssessments } from "../../_request";
import { useNavigate } from "react-router-dom";
import { Skeleton, Grid } from "@mui/material";
import { odooFilterFieldsService } from "../../../core/_request";
import { useAuth } from "../../../../auth";

function ProfessionalHelp() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [catSubcatData, setCatSubcatData] = useState();

  useEffect(() => {
    odooFilterFieldsService(currentUser?.UserId).then((resp) => {
      const filteredData = resp?.data.filter(item => item.Name !== "I Don't Know");
      setCatSubcatData(filteredData);
    });
  }, [currentUser?.UserId]);

  console.log("catSubcatData", catSubcatData);

  const professionalHelpList = [
    {
      image: "/assets/img/dashboard/heart2.png",
      text: "Psychologist",
      uri: "/?Profession=Psychologist",
    },
    {
      image: "/assets/img/dashboard/face2.png",
      text: "Psychiatrist",
      uri: "/?Profession=Psychiatrist",
    },
    {
      image: "/assets/img/dashboard/flower2.png",
      text: "Wellness Expert",
      uri: "/?Profession=Wellness Expert",
    },
    //{ image: "/assets/img/dashboard/heart_shield.png", text: "Life Coach", uri: "/?Profession=Life Coach" },
  ];

  const [assessmentsData, setAssessmentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const { assessments } = await getRecommendedAssessments();

        setAssessmentsData(assessments);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching random experts:", error);
      }
    };

    fetchAssessments();
  }, []);

  return (
    <div className="">
      <div
        className=""
        style={{
          backgroundColor: "",
          borderRadius: "8px",
          boxShadow: " ",
        }}
      >
        <div className="row main-card">
          <div className="col-md-6 mx-0">
            <div
              className="left-card pt-2 pb-2"
              style={{ minHeight: "280px", maxHeight: "330px" }}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="25%"
                    height="35px"
                    className="rounded rounded-3 mx-4 my-2"
                  />
                  <div className="row px-3 mt-0 m-0 px-2">
                    <Grid container spacing={2}>
                      {Array.from(new Array(4)).map((_, index) => (
                        <Grid item xs={6} key={index}>
                          <div
                            style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
                            className="p-4 rounded rounded-3"
                          >
                            <div className="d-flex w-100 align-items-center justify-content-center gap-2">
                              <Skeleton
                                variant="rounded"
                                width={48}
                                height={48}
                                className="rounded rounded-3"
                              />
                              <Skeleton
                                variant="rounded"
                                width="50%"
                                height="20px"
                                className="rounded rounded-3"
                              />
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </>
              ) : (
                <>
                  <div className="title1 ms-md-4 p-2">
                    <h5
                      className=" m-0 p-0 ms-1"
                      style={{ fontWeight: "bold" }}
                    >
                      Professional Help
                    </h5>
                  </div>
                  <div className="row px-3 mt-0 m-0 py-2">
                    {catSubcatData?.map((a, i) => (
                      <div
                        key={i}
                        className="col-6 professionalHelp--Card"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="rectangle-card  px-2 py-3 my-2">
                          <div
                            className="row m-0 p-0 d-flex align-items-center"
                            onClick={() => {
                              navigate(`/?Profession=${a?.Query}`);
                            }}
                          >
                            <div className="d-flex col-lg-4 col-sm-6">
                              <img
                                alt=""
                                src={`/assets/img/dashboard/${a.Name}.png`}
                                className=" img-fluid"
                                width="50"
                              />
                            </div>

                            <h6 className="col-lg-8 text-xs-nowrap flex-wrap text-left m-0 p-0 col-sm-6">
                              {a?.Name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-md-6 my-3 my-md-0 mt-xs-2">
            <div
              className="cardsl pt-2 pb-2"
              style={{ minHeight: "280px", maxHeight: "300px" }}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="25%"
                    height="35px"
                    className="rounded rounded-3 mx-4"
                  />

                  <div className="d-flex pb-3 mx-3 mt-4" style={{ overflow: 'hidden' }}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <div
                        className="assessment-card border border"
                        style={{
                          minWidth: "200px",
                          maxWidth: "200px",
                          marginRight: "10px",
                          backgroundColor: "rgb(0,0,0,0.08)",
                        }}
                        key={index}
                      >
                        <div
                          className="card-body px-4 mt-3"
                          style={{
                            minHeight: "120px",
                            maxHeight: "120px",
                            position: "relative",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <Skeleton
                              variant="rounded"
                              width="50%"
                              height="25px"
                              className="rounded rounded-pill"
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-center w-100 mt-3">
                            <Skeleton
                              variant="text"
                              width="75%"
                              height="25px"
                              className="rounded rounded-3"
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-center w-100 mt-2">
                            <Skeleton
                              variant="rounded"
                              width="50%"
                              height="25px"
                              className="rounded rounded-3"
                            />
                          </div>
                        </div>

                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="40px"
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="title1 ms-md-4 py-2 px-2">
                    <h5
                      className=" m-0 p-0 ms-1"
                      style={{ fontWeight: "bold" }}
                    >
                      Recommended Assessments
                    </h5>
                  </div>

                  <div className="scroll-container d-flex pb-3 mx-3 mt-4  ">
                    {assessmentsData?.map((item) => (
                      <div
                        key={item.id}
                        className="assessment-card border border"
                        style={{
                          minWidthidth: "150px",
                          maxWidth: "200px",
                          marginRight: "10px",
                        }}
                      >
                        <div
                          className="card-body px-4 mt-3"
                          style={{
                            minHeight: "120px",
                            maxHeight: "120px",
                            position: "relative",
                          }}
                        >
                          <div>
                            <span
                              className="badge rounded-pill px-3 py-2"
                              style={{
                                fontWeight: "bold",
                                background: "#EFEAFF",
                                color: "#8D66FF",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="bi bi-clock mb-1 mx-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                              </svg>

                              {item.time}
                            </span>
                          </div>

                          <p className="fw-bold mt-2 mb-2 text-wrap title-ellipsis" title={item?.name}>
                            {item.name}
                          </p>

                          <div
                            className=""
                            style={{
                              width: "75%",
                              position: "absolute",
                              bottom: "0",
                            }}
                          >
                            <p
                              className="text-secondary pb-2  text-center"
                              style={{ fontSize: "13px" }}
                            >
                              {item.count}
                            </p>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            navigate(
                              `/stella?message=${item.action.parameter.firstmessage.message}`
                            );
                          }}
                          className="cardfooter py-2 px-5 text-light"
                          style={{
                            backgroundColor: " var(--primary-color)",
                            cursor: "pointer",
                          }}
                        >
                          Let&apos;s Start
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalHelp;