import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ProfileCard from "./ProfileCard";
import React, { Component, useEffect, useState } from "react";
import {
  getExpertListWithFilter_Web_V3Service,
  FilterFieldsService,
  validate_token,
  odooFilterFieldsService,
} from "./core/_request";
import {
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import { getCountryCode } from "./core/commonFun";
import { Button, Tab, Tabs } from "react-bootstrap";
import "./ExpertFilterDrawer.css";
import Cookies from "universal-cookie";
import Skeleton from "@mui/material/Skeleton";
import { useAuth } from "../auth";

export default class SearchView extends Component {
  componentDidMount = () => {
    this.setState({ loading: true });
    var _interval = setInterval(() => {
      const countryCode = getCountryCode();
      if (countryCode) {
        this.setState({ loading: false });
        clearInterval(_interval);
      }
    }, 1000);
  };

  render() {
    return (this.state?.loading && <div>...</div>) || <Search />;
  }
}

const Search = () => {
  const [searchBarParams, setSearchBarParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [catSubcatData, setCatSubcatData] = useState([]);
  const [subcatData, setSubcatData] = useState([]);
  const [filtershow, setFiltershow] = useState(false);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const search = useLocation().search;
  const Profession_url = new URLSearchParams(search).get("Profession");
  const Category_url = new URLSearchParams(search).get("Category");
  const Experience_url = new URLSearchParams(search).get("Experience");
  const Minprice_url = new URLSearchParams(search).get("Minprice");
  const Maxprice_url = new URLSearchParams(search).get("Maxprice");
  const q_url = new URLSearchParams(search).get("q");
  const countryCode = getCountryCode();

  const [final_selected, setFinal_selected] = useState([]);
  const [area_of_conseron_selected, setArea_of_conseron_selected] = useState([]);
  const [experience_selected, setExperience_selected] = useState("");
  const [price_selected, setPrice_selected] = useState({});
  const [columns, setColumn] = useState(3);
  const [catColumn, setCatColumn] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 650) {
        setColumn(1);
        setCatColumn(2);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const token_url = new URLSearchParams(search).get("token");

  const saveCookie = (firstName, oneYearFromNow) => {
    const cookies = new Cookies();
    cookies.set("displayName", firstName, {
      expires: new Date(oneYearFromNow),
      domain: ".unitedwecare.com",
      path: "/",
    });
  };

  useEffect(() => {
    if (token_url) {
      validate_token(token_url).then((resp) => {
        if (resp?.ResponseCode === 200) {
          localStorage.setItem("Authorization", resp?.data?.usertoken);
          localStorage.setItem("profile", JSON.stringify(resp?.data?.userdetails));
          const now = new Date();
          const oneYearFromNow = now.setFullYear(now.getFullYear() + 1);
          const firstName = resp?.data?.userdetails?.Name.split(" ")[0];

          if (resp?.data?.userdetails.header_image) {
            navigate("/user-dashboard");
          }

          saveCookie(firstName, oneYearFromNow);
        }
      });
    }
  }, [token_url, navigate]);

  const handleChange_Area_of_concern = (event) => {
    const { checked, value } = event.currentTarget;
    const Json_val = JSON.parse(value);
    setArea_of_conseron_selected((prev) =>
      checked
        ? [...prev, Json_val]
        : prev.filter((val) => val?.Id !== Json_val?.Id)
    );
    setIsFilterSelected(true);
  };

  const handleChange_Experience = (event) => {
    const { checked, value } = event.currentTarget;
    if (checked) {
      setExperience_selected(value);
    } else {
      setExperience_selected("");
    }
    setIsFilterSelected(true);
  };

  const handel_Apply_Filter = () => {
    setFinal_selected({
      area_of_conseron: area_of_conseron_selected,
      experience: experience_selected,
      price: price_selected,
    });
    setFiltershow(false);
    setData([]);
    setPageNumber(0);
    binddata();

    if (area_of_conseron_selected.length)
      searchBarParams.set(
        "Category",
        area_of_conseron_selected.map((item) => item.Name).toString()
      );
    if (experience_selected)
      searchBarParams.set("Experience", experience_selected);
    if (price_selected.Min)
      searchBarParams.set("Minprice", price_selected.Min);
    if (price_selected.Max)
      searchBarParams.set("Maxprice", price_selected.Max);

    setSearchBarParams(searchBarParams);
  };

  const handel_Clear_Filter = () => {
    setFinal_selected([]);
    setArea_of_conseron_selected([]);
    setExperience_selected("");
    setFiltershow(false);
    setPrice_selected({});
    if (searchBarParams.has("Category")) searchBarParams.delete("Category");
    if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
    if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
    if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
    setSearchBarParams(searchBarParams);
    setIsFilterSelected(false);
  };

  const handel_remove_area_of_conseron = (item) => {
    if (searchBarParams.has("Category")) searchBarParams.delete("Category");
    setSearchBarParams(searchBarParams);
    setIsFilterSelected(false);

    setArea_of_conseron_selected((prevState) =>
      prevState.filter((prevItem) => prevItem.Id !== item.Id)
    );

    setFinal_selected((prevState) => ({
      ...prevState,
      area_of_conseron: prevState.area_of_conseron.filter(
        (prevItem) => prevItem.Id !== item.Id
      ),
    }));
    setData([]);
    setPageNumber(0);
    binddata();
  };

  const handel_remove_experience = () => {
    if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
    setSearchBarParams(searchBarParams);
    setIsFilterSelected(false);

    setExperience_selected("");

    setFinal_selected((prevState) => ({
      ...prevState,
      experience: "",
    }));
    setData([]);
    setPageNumber(0);
    binddata();
  };

  const handel_remove_price = () => {
    if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
    if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
    setSearchBarParams(searchBarParams);
    setIsFilterSelected(false);

    setPrice_selected({});

    setFinal_selected((prevState) => ({
      ...prevState,
      price: {},
    }));
    setData([]);
    setPageNumber(0);
    binddata();
  };

  useEffect(() => {
    if (!Profession_url && !isFirstLoad) {
      navigate("/?Profession=Psychologist");
    }
    setPageNumber(0);
    binddata();

    getCategoryData();
  }, [Profession_url]);

  useEffect(() => {
    if (catSubcatData.length > 0 && Profession_url) {
      const item = catSubcatData.find((o) => o.Name === Profession_url);
      if (item) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          Category: null,
          Profession: item.Name,
        }));
        setSubcatData(item.Data || []);
      }
    }
  }, [catSubcatData, Profession_url]);

  const getCategoryData = () => {
    odooFilterFieldsService(currentUser?.UserId).then((resp) => {
      var newData = resp?.data?.filter(
        (o) => countryCode == "IN" || o.Id !== 172
      );
      setCatSubcatData(newData);
    });
  };

  useEffect(() => {
    binddata();
  }, [pageNumber]);

  useEffect(() => {
    if (!isFirstLoad && catSubcatData.length) {
      setIsFirstLoad(true);
      if (
        (Profession_url == null || Profession_url == "Psychologist") &&
        !Category_url &&
        !Experience_url &&
        !Minprice_url &&
        !Maxprice_url &&
        !q_url
      ) {
        onCatChange(70, false);
      }
    }
  }, [catSubcatData, isFirstLoad, Profession_url, Category_url, Experience_url, Minprice_url, Maxprice_url, q_url]);

  useEffect(() => {
    binddata();
  }, [searchBarParams]);

  const onFilterChange = (key, value) => {
    var newFilters = JSON.parse(JSON.stringify(filters ?? {}));
    newFilters[key] = value;
    setPageNumber(0);
    setFilters(newFilters);
    searchBarParams.set(key, value);
    setSearchBarParams(searchBarParams);
  };

  const binddata = () => {
    setLoading(true);
    var req = {
      Country: "India",
      q: filters?.q ?? q_url,
      Profession: Profession_url,
      Experience: experience_selected ?? Experience_url,
      SortBy: filters?.SortBy,
      MinPrice: price_selected?.Min ?? Minprice_url ?? 0,
      MaxPrice: price_selected?.Max ?? Maxprice_url ?? 20000,
      PageNumber: pageNumber,
      RowsOfPage: 3,
    };
    if (area_of_conseron_selected?.length > 0) {
      req["Category"] = area_of_conseron_selected
        ?.map((item) => item.Name)
        .toString();
    } else {
      req["Category"] = Category_url;
    }

    getExpertListWithFilter_Web_V3Service(req).then((resp) => {
      var tdata = resp?.data;
      tdata?.map((o) => {
        if (countryCode == "IN")
          o["countryAmount"] = o.Hourly_Consultaion_Fee_amount;
        else o["countryAmount"] = o.USD_Amount;

        return o;
      });
      if (pageNumber == 0) {
        setData(tdata);
      } else {
        var nData = [...data, ...tdata];
        setData(nData);
      }
      setLoading(false);
      setIsLoading(false);
      setIsLoadMore(resp?.data?.length === 3);
    });
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const onCatChange = (Id, isPressed) => {
    setData([]);
    if (isPressed) {
      if (searchBarParams.has("Category")) searchBarParams.delete("Category");
      if (searchBarParams.has("Experience"))
        searchBarParams.delete("Experience");
      if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
      if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
    }
    handel_Clear_Filter();
    if (Id == 1) {
      setFilters({ ...filters, Category: null, Profession: "Not Know" });
      setSubcatData([]);
      searchBarParams.set("Profession", "Not Know");
      setSearchBarParams(searchBarParams);
    } else {
      var item = catSubcatData?.find((o) => o?.Id === Id);
      setFilters({ ...filters, Category: null, Profession: item?.Name });
      setSubcatData(item?.Data || []);
      searchBarParams.set("Profession", item?.Name);
      setSearchBarParams(searchBarParams);
    }
  };

  console.log("final_selected", final_selected.length)

  return (
    <div style={{ background: "#F7F2FF" }}>
      <section className="pt-5">
        <div className="container d-flex flex-column gap-3">
          {isLoading ? (
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center my-2 ">
              <Skeleton
                variant="rounded"
                width="200px"
                height="35px"
                className="rounded rounded-3"
              />
              <Skeleton
                variant="rounded"
                width="350px"
                height="25px"
                className="rounded rounded-3"
              />
              <Skeleton
                variant="rounded"
                width="320px"
                height="50px"
                className="rounded rounded-3 mt-2"
              />
            </div>
          ) : (
            <>
              <h1
                className="font-inter font-w600 fs-2 lh-base text-center"
                style={{ marginBottom: "-20px" }}
              >
                Find a Professional
              </h1>
              <p className="font-inter fs-6 text-center">
                Get the support from the right professionals - fast,
                confidential, & right from your home
              </p>
              <Col xs={12} md={3} className="mx-auto">
                <InputGroup className="mb-3 lh-1 my-3 rounded-3">
                  <Form.Control
                    className="custom-input border-end-0"
                    style={{ height: "50px" }}
                    type="text"
                    value={q_url}
                    placeholder="Search “Professionals” "
                    onChange={(e) => onFilterChange("q", e.target.value)}
                  />
                  <InputGroup.Text>
                    <img src="/assets/img/search_icon.png" alt="" />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </>
          )}

          <div className="d-flex justify-content-center">
            <ButtonGroup
              className="d-flex flex-row align-items-center hideScrollbar gap-2"
              style={{ overflowX: "scroll" }}
            >
              {!isLoading ? (
                <>
                  {catSubcatData &&
                    catSubcatData
                      ?.filter(
                        (o) => countryCode === "IN" || o?.Name != "Psychiatrist"
                      )
                      ?.map(
                        (item, index) =>
                          item && (
                            <div key={index}>
                              <ToggleButton
                                onClick={(e) => onCatChange(item.Id, true)}
                                type="radio"
                                name="radio"
                                className="rounded-2 col-2 px-1 py-1 ms-1 border-0"
                                style={{
                                  background:
                                    Profession_url === item?.Query
                                      ? "#73589b"
                                      : "#fff",
                                  color:
                                    Profession_url === item?.Query
                                      ? "#FFFFFF"
                                      : "#21231E",
                                  border: "1px solid #50B3EF",
                                  minWidth: "140px",
                                  maxWidth: "160px",
                                  height: "40px",
                                }}
                              >
                                <p className="font-inter fs-6 text-center lh-lg">
                                  {item?.Name}
                                </p>
                              </ToggleButton>
                            </div>
                          )
                      )}
                  {/* <ToggleButton
                    onClick={(e) => onCatChange("Not Know", true)}
                    type="radio"
                    name="radio"
                    className="rounded-2 col-2 px-1 py-1 ms-1 border-0"
                    style={{
                      background:
                        Profession_url === "Not Know" ? "#73589b" : "#fff",
                      color:
                        Profession_url === "Not Know" ? "#FFFFFF" : "#21231E",
                      border: "1px solid #50B3EF",
                      minWidth: "110px",
                      maxWidth: "150px",
                      height: "40px",
                    }}
                  >
                    <p className="font-inter fs-6 text-center lh-lg">
                      I Don't Know
                    </p>
                  </ToggleButton> */}
                </>
              ) : (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "15px",
                    width: "100%",
                  }}
                >
                  {Array.from(new Array(4)).map((_, index) => (
                    <Skeleton variant="rounded" width="140px" height="40px" />
                  ))}
                </div>
              )}
            </ButtonGroup>
          </div>
          {filters?.Profession == "Not Know" && (
            <h6 className="p-2 mt-3 text-center fs-2">
              It’s okay to not know.
              <br />
              <span className="fs-6 font-w500">
                {" "}
                Together we can figure out how we can help you further.
              </span>{" "}
              <br />
              <span className="fs-6 font-w500">
                {" "}
                These are the experts who can guide you better.
              </span>
            </h6>
          )}

          {filters?.Profession != "Not Know" && (
            <>
              <div className="d-flex align-items-center gap-3">
                {isLoading ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width="120px"
                      height="50px"
                      className="rounded rounded-3"
                    />
                  </>
                ) : (
                  <>
                    {filters?.Profession != "Not Know" && (
                      <span
                        style={{ cursor: "pointer" }}
                        className="d-flex align-items-center gap-2 text-decoration-none"
                        onClick={() => setFiltershow(true)}
                      >
                        <img src="/assets/img/collective_files_icon.png" alt="" />
                        <p className="font-inter fs-6 text-black">Filters</p>
                      </span>
                    )}
                    {filters?.Profession != "Not Know"}
                  </>
                )}
                { }

                <div
                  className="d-flex align-items-center hideScrollbar gap-2"
                  style={{ overflowX: "scroll", marginLeft: 12, height: 35 }}
                >
                  {isLoading ? (
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "15px",
                          width: "100%",
                        }}
                      >
                        {Array.from(new Array(3)).map((_, index) => (
                          <Skeleton
                            variant="text"
                            width={110}
                            height={30}
                            className="rounded rounded-pill"
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    (filters?.Profession === "Psychologist" &&
                      (!final_selected?.area_of_conseron ||
                        final_selected?.area_of_conseron?.length === 0 ||
                        final_selected?.experience?.length === 0 ||
                        final_selected?.price?.length === 0) &&
                      subcatData &&
                      subcatData
                        .filter((o) => o.Id == 109 || o.Id == 104 || o.Id == 105)
                        .map((item, index) => (
                          <h2
                            className="d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6 m-0"
                            style={{
                              background: "#CCB6EC",
                              border: "1px solid #CCB6EC",
                              whiteSpace: "nowrap",
                              marginLeft: 3,
                            }}
                          >
                            {item.Name}
                          </h2>
                        ))) ||
                    ((!final_selected?.area_of_conseron ||
                      final_selected?.area_of_conseron?.length === 0 ||
                      final_selected?.experience?.length === 0 ||
                      final_selected?.price?.length === 0) &&
                      subcatData &&
                      subcatData.slice(0, 3).map((item, index) => (
                        <h2
                          className="d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6"
                          style={{
                            background: "#CCB6EC",
                            border: "1px solid #CCB6EC",
                            whiteSpace: "nowrap",
                            marginLeft: 3,
                          }}
                        >
                          {item.Name}
                        </h2>
                      )))
                  )}
                  {final_selected &&
                    final_selected?.area_of_conseron?.map((item, index) => (
                      <span
                        onClick={(e) => {
                          handel_remove_area_of_conseron(item);
                        }}
                        className="d-flex text-decoration-none gap-2 rounded-5 px-3 align-items-center fs-6 "
                        style={{
                          background: "#CCB6EC",
                          color: "#fff",
                          border: "1px solid #CCB6EC",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          marginRight: 3,
                          display: "-ms-inline-flexbox",
                          padding: 2,
                          lineHeight: 2,
                        }}
                      >
                        {" "}
                        <img
                          src="/assets/img/closeIcon.png"
                          alt="Filter"
                          style={{ height: 10, width: 10 }}
                        />{" "}
                        &nbsp; {item.Name}
                      </span>
                    ))}
                  {final_selected && final_selected?.experience && (
                    <span
                      onClick={(e) => {
                        handel_remove_experience(final_selected?.experience);
                      }}
                      className="d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6"
                      style={{
                        background: "#CCB6EC",
                        color: "#fff",
                        border: "1px solid #CCB6EC",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        marginRight: 3,
                        display: "-ms-inline-flexbox",
                        padding: 2,
                        lineHeight: 2,
                      }}
                    >
                      {" "}
                      <img
                        src="/assets/img/closeIcon.png"
                        alt="Filter"
                        style={{ height: 10, width: 10 }}
                      />{" "}
                      &nbsp; {final_selected?.experience}
                      {"s"}
                    </span>
                  )}
                  {final_selected && final_selected.length > 0 && final_selected?.price && (
                    <span
                      onClick={(e) => {
                        handel_remove_price(final_selected?.price);
                      }}
                      className=" d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6"
                      style={{
                        background: "#CCB6EC",
                        color: "#fff",
                        border: "1px solid #CCB6EC",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        marginRight: 3,
                        display: "-ms-inline-flexbox",
                        padding: 2,
                        lineHeight: 2,
                      }}
                    >
                      {" "}
                      <img
                        src="/assets/img/closeIcon.png"
                        alt="Filter"
                        style={{ height: 10, width: 10 }}
                      />{" "}
                      &nbsp; {final_selected?.price?.Min} to{" "}
                      {final_selected?.price?.Max}
                    </span>
                  )}
                </div>
              </div>
              <Modal
                show={filtershow}
                onHide={() => setFiltershow(false)}
                dialogClassName="left-modal"
                aria-labelledby="example-custom-modal-styling-title"
                className="m-0 col-8"
              >
                <div>
                  <div
                    className="m-0 d-flex flex-column leftModal--body"
                  // style={{ height: "90vh", width: "100%" }}
                  >
                    <Modal.Header className="justify-content-end" closeButton>
                      <Modal.Title
                        className="font-inter fs-5 text-end ms-auto text-black"
                        id="example-custom-modal-styling-title"
                      >
                        Filter search results
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        flex: 1,
                        scrollBehavior: "smooth",
                        overflowY: "scroll",
                      }}
                    >
                      <Tabs
                        defaultActiveKey="area-of-concern"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                      >
                        <Tab
                          eventKey="area-of-concern"
                          title="Area of Interest"
                          className="filterTab"
                        >
                          <div
                            className="mb-md-3 areaOfInterestTab"
                            style={{
                              height: "100%",
                            }}
                          >
                            {subcatData &&
                              subcatData.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    padding: 5,
                                    alignItems: "center",
                                    marginLeft: 15,
                                    display: "flex",
                                  }}
                                >
                                  <input
                                    id={`Area_of_concern${index}`}
                                    value={JSON.stringify(item)}
                                    type="checkbox"
                                    checked={area_of_conseron_selected?.some(
                                      (val) => val.Id === item.Id
                                    )}
                                    onChange={handleChange_Area_of_concern}
                                    style={{
                                      zoom: 1.3,
                                      appearance: "auto",
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  <label
                                    htmlFor={`Area_of_concern${index}`}
                                    className="fs-6"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.Name}
                                  </label>
                                </div>
                              ))}
                          </div>

                        </Tab>
                        <Tab
                          eventKey="experience"
                          title="Years of Experience"
                          className="font-inter fs-5 font-w400"
                        >
                          <div
                            style={{
                              padding: 5,
                              alignItems: "center",
                              marginLeft: 15,
                              display: "flex",
                            }}
                          >
                            <input
                              id={`Year_1`}
                              name="Year"
                              value={"0-5 Year"}
                              type="checkbox"
                              checked={experience_selected === "0-5 Year"}
                              onChange={handleChange_Experience}
                              style={{ zoom: 1.3 }}
                            />{" "}
                            &nbsp;&nbsp;
                            <label for={`Year_1`} className="fs-6">
                              {"0 - 5 Years"}
                            </label>
                          </div>
                          <div
                            style={{
                              padding: 5,
                              alignItems: "center",
                              marginLeft: 15,
                              display: "flex",
                            }}
                          >
                            <input
                              id={`Year_2`}
                              name="Year"
                              value={"6-10 Year"}
                              type="checkbox"
                              checked={experience_selected === "6-10 Year"}
                              onChange={handleChange_Experience}
                              style={{ zoom: 1.3 }}
                            />{" "}
                            &nbsp;&nbsp;
                            <label for={`Year_2`} className="fs-6">
                              {"6 - 10 Years"}
                            </label>
                          </div>
                          <div
                            style={{
                              padding: 5,
                              alignItems: "center",
                              marginLeft: 15,
                              display: "flex",
                            }}
                          >
                            <input
                              id={`Year_3`}
                              name="Year"
                              value={"11-15 Year"}
                              type="checkbox"
                              checked={experience_selected === "11-15 Year"}
                              onChange={handleChange_Experience}
                              style={{ zoom: 1.3 }}
                            />{" "}
                            &nbsp;&nbsp;
                            <label for={`Year_3`} className="fs-6">
                              {"11 - 15 Years"}
                            </label>
                          </div>
                          <div
                            style={{
                              padding: 5,
                              alignItems: "center",
                              marginLeft: 15,
                              display: "flex",
                            }}
                          >
                            <input
                              id={`Year_4`}
                              name="Year"
                              value={"> Above 16 Year"}
                              type="checkbox"
                              checked={experience_selected === "> Above 16 Year"}
                              onChange={handleChange_Experience}
                              style={{ zoom: 1.3 }}
                            />{" "}
                            &nbsp;&nbsp;
                            <label for={`Year_4`} className="fs-6">
                              {"> Above 16 Years"}
                            </label>
                          </div>
                        </Tab>
                      </Tabs>
                    </Modal.Body>
                    <div
                      className="d-flex justify-content-around"
                    >
                      {(final_selected?.length === 0 && (
                        <button
                          disabled
                          style={{
                            cursor: "pointer !important",
                            backgroundColor: "rgb(255, 255, 255)",
                            flex: 1,
                            borderRadius: "none",
                            color: "#666",
                          }}
                          className="rounded-0 border-0 text-black font-inter fs-6 col-5 px-4 py-3"
                        >
                          Clear All
                        </button>
                      )) || (
                          <button
                            onClick={handel_Clear_Filter}
                            style={{
                              cursor: "pointer !important",
                              backgroundColor: "rgb(255, 255, 255)",
                              flex: 1,
                              borderRadius: "none",
                            }}
                            className="rounded-0 border-0 text-black font-inter fs-6"
                          >
                            Clear All
                          </button>
                        )}
                      <Button
                        disabled={!isFilterSelected}
                        onClick={handel_Apply_Filter}
                        className="rounded-0 border-0 text-white font-inter fs-6 px-4 col-5 py-3"
                        style={{
                          background: "var(--button-primary-color)",
                          color: "#FFF",
                          flex: 1,
                          cursor: "pointer !important",
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </section>

      <div className="container my-3">
        <section className="mt-40 sec-pb-120">
          <div className="">
            <div className="row row-30-wrap gx-3 gx-lg-12 brand-row">
              {data?.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                  <ProfileCard
                    key={index}
                    item={item}
                    filters={filters?.Profession == "Not Know"}
                    loading={loading}
                  />
                </div>
              ))}

              {loading && (
                <div className="row row-30-wrap gx-3 gx-lg-12 brand-row">
                  {Array.from(new Array(3)).map((_, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          backgroundColor: "rgba(0, 0, 0, 0.09)",
                          marginTop: "10px",
                        }}
                        className="rounded rounded-3"
                      >
                        <div
                          style={{
                            padding: "45px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            width="130px"
                            height="130px"
                            className="rounded rounded-3"
                          />
                          <div className="d-flex flex-column w-50">
                            {Array.from(new Array(4)).map((_, index) => (
                              <Skeleton
                                variant="text"
                                height={30}
                                key={index}
                              />
                            ))}
                          </div>
                        </div>
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={100}
                          className="rounded rounded-3"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!loading && isLoadMore && filters?.Profession !== "Not Know" && (
                <center>
                  <button
                    onClick={nextPage}
                    className="ms-auto ms-xl-0 rounded-5 px-3 border-2 py-1"
                    style={{
                      border: "2px solid var(--primary-color)",
                      background: "white",
                      color: "var(--primary-color)",
                    }}
                  >
                    Load More
                  </button>
                </center>
              )}
            </div>

            {!loading && data?.length === 0 && (
              <div className="my-5">
                <p className="font-inter fs-5 text-center">No Expert Found</p>
                <p className="font-inter fs-5 text-center">
                  Please Modify Your Search
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
