import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import InputLabel from '@mui/material/InputLabel';
import { CreateCommunityPost, getAllClubsByCategory, getCategoryList } from '../_request';
import { useAuth } from '../../../auth';
import moment from 'moment';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../firebaseConfig';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar";

import { useSnackbar } from 'notistack';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const CreateVideo = ({ closeModal }) => {
    const fileInputRef = useRef();
    const [step, setStep] = useState(1);
    const [categoryList, setCategoryList] = useState();
    const [categoryId, setCategoryId] = useState();
    const [clubList, setClubList] = useState();
    const [selectedClubs, setSelectedClubs] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [thumbSelectedFile, setThumbSelectedFile] = useState();
    const [descriptionText, setDescriptionText] = useState();
    const [videoTitle, setVideoTitle] = useState();
    const [capturedImage, setCapturedImage] = useState(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [thumbUrl, setThumbUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMsg, setSucessMsg] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { currentUser } = useAuth();
    const userId = currentUser?.UserId;
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (step === 1) {
            getCategoryList(userId).then(resp => {
                if (resp?.ResponseCode === 200) {
                    setCategoryList(resp?.data);
                } else {
                    enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
                }
            });
        } else if (step === 2) {
        }
    }, [userId, step]);

    const handleCategoryChange = (event) => {
        const selectedCategory = categoryList?.category.find(category => category.id === event.target.value);
        setCategoryId(event.target.value)

        getAllClubsByCategory({ userId, catId: event.target.value }).then(resp => {
            if (resp?.ResponseCode === 200) {
                setClubList(resp?.data)
            } else {
                console.log(resp.ResponseMessage)
            }
        })
    };

    const handleFileChange = (event, thum) => {
        debugger
        setLoading(true);
        setSelectedFile(event.target.files[0]);
        const file = event.target.files[0];
        if (file) {
            if (
                file?.type === "image/png" ||
                file?.type === "image/jpg" ||
                file?.type === "image/jpeg" ||
                file?.type === "application/pdf" ||
                file?.type === "video/mp4" ||
                file?.type === "video/quicktime" ||
                file?.type === "video/3gpp" ||
                file?.type === "video/x-msvideo" ||
                file?.type === "video/x-flv" ||
                file?.type === "video/x-matroska" ||
                file?.type === "video/webm" ||
                file?.type === "video/ogg" ||
                file?.type === "video/avi" ||
                file?.type === "video/mpeg" ||
                file?.type === "video/webm;codecs=vp8,opus"
            ) {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file?.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                    },
                    (err) => console.log(err),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            debugger
                            if (thum === "thumb") {
                                setThumbUrl(url);
                            } else {
                                setVideoUrl(url);
                                setCapturedImage(url);
                            }
                            setLoading(false);
                        });
                    }
                );
            }
        } else {
            setLoading(false);
        }
    };

    const handleThumbFileUpload = (event, thum) => {
        setLoading(true);
        setThumbSelectedFile(event.target.files[0]);
        const file = event.target.files[0];
        if (file) {
            if (
                file?.type === "image/png" ||
                file?.type === "image/jpg" ||
                file?.type === "image/jpeg" ||
                file?.type === "application/pdf" ||
                file?.type === "video/mp4" ||
                file?.type === "video/quicktime" ||
                file?.type === "video/3gpp" ||
                file?.type === "video/x-msvideo" ||
                file?.type === "video/x-flv" ||
                file?.type === "video/x-matroska" ||
                file?.type === "video/webm" ||
                file?.type === "video/ogg" ||
                file?.type === "video/avi" ||
                file?.type === "video/mpeg" ||
                file?.type === "video/webm;codecs=vp8,opus"
            ) {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file?.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                    },
                    (err) => console.log(err),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            debugger
                            setThumbUrl(url);
                            setLoading(false);
                        });
                    }
                );
            }
        } else {
            console.error("No file selected");
            setLoading(false);
        }
    };

    const removeFile = () => {
        setThumbUrl(null);
        setVideoUrl(null);
        setCapturedImage(null);  
        setSelectedFile(null);
    };

    const onSelectClubs = (item, index) => {
        setSelectedClubs(item?.id);
        setSelectedIndex(index);
    }

    const submitCreatePost = () => {
        if (categoryId === 0 || categoryId === undefined || categoryId === null) {
            setErrorMessage("Category is mandatory field !");
            setOpenSnackbar(true);
            return;
        } else if (videoTitle === '' || videoTitle === null || videoTitle === undefined) {
            setErrorMessage("Video Title is mandatory field !");
            setOpenSnackbar(true);
            return;
        } else if (videoUrl === '' || videoUrl === null || videoUrl === undefined) {
            setErrorMessage("Video is mandatory field !");
            setOpenSnackbar(true);
            return;
        } else if (thumbUrl === '' || thumbUrl === null || thumbUrl === undefined) {
            setErrorMessage("Video Thumbnail is mandatory field !");
            setOpenSnackbar(true);
            return;
        } else if (selectedClubs === 0 || selectedClubs === undefined || selectedClubs === null) {
            setErrorMessage("Club is mandatory field !");
            setOpenSnackbar(true);
            return;
        }
        const body = {
            "userId": userId,
            "postType": "video",
            "title": videoTitle,
            "description": descriptionText,
            "content": false,
            "clubId": selectedClubs,
            "categoryId": categoryId,
            "videoUrl": videoUrl,
            "thumbnailUrl": thumbUrl,
            "audioUrl": "",
            "imageUrl": "",
            "duration": null
        }
        CreateCommunityPost(body).then(resp => {
            if (resp?.ResponseCode === 200 || resp?.ResponseCode === 201) {
                setSucessMsg(resp.ResponseMessage);
            } else {
                setSucessMsg(resp.ResponseMessage)
            }
            closeModal();
        })
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div className='createPost'>
            {step === 1 &&
                <>
                    <TextField
                        id="outlined-select-category"
                        select
                        label="Choose your category"
                        className='w-100 my-2'
                        onChange={handleCategoryChange}
                        value={categoryId}
                    >
                        {categoryList?.category?.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={option?.imageUrl} alt='' height={18} width={18} className='me-3' />
                                    <span style={{ marginLeft: '8px' }}>{option.name}</span>
                                </div>
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-multiline-static"
                        label="Video Title *"
                        className='w-100 my-2'
                        onChange={(e) => setVideoTitle(e.target.value)}
                        value={videoTitle}
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Write description..."
                        multiline
                        rows={1}
                        className='w-100 my-2'
                        onChange={(e) => setDescriptionText(e.target.value)}
                        value={descriptionText}
                    />
                    <FormControl className='w-100 my-2' variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-weight">Where to post</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end"><ArrowForwardOutlinedIcon sx={{ color: 'var(--primary-color)' }} onClick={() => setStep(2)} /></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            className='w-100'
                        />
                    </FormControl>

                    <Button
                        component="label"
                        variant="outlined"
                        endIcon={<FileUploadOutlinedIcon fontSize='large' sx={{ color: 'var(--primary-color)' }} />}
                        className='w-100 my-2 d-flex justify-content-between uploadBtn'
                        style={{ height: '56px' }}
                    >
                        {capturedImage && selectedFile?.name || "Upload Video  (max size: 200mb)"}
                        {!capturedImage && (
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => handleFileChange(event)}
                                ref={fileInputRef}
                                accept='video/*'
                            />
                        )}
                    </Button>


                    <Button
                        component="label"
                        variant="outlined"
                        endIcon={<FileUploadOutlinedIcon fontSize='large' sx={{ color: 'var(--primary-color)' }} />}
                        className='w-100 my-2 d-flex justify-content-between uploadBtn'
                        style={{ height: thumbUrl ? 'auto' : '56px' }}
                    >
                        {thumbUrl && (
                            <div className='captureImageDiv'>
                                <CloseIcon onClick={removeFile} className='deleteFileBtn' />
                                <img src={thumbUrl} alt='' />
                            </div>
                        )}
                        {thumbUrl && selectedFile?.name || "Upload Thumbnail (PNG, JPG, JPEG)"}
                        {!thumbUrl && (
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => handleThumbFileUpload(event)}
                                ref={fileInputRef}
                                accept='image/*'
                            />
                        )}
                    </Button>

                    <Button variant='contained' className='w-50 postSubmit' onClick={submitCreatePost}>Done</Button>
                </>
            }
            {
                step === 2 &&
                <div className='stepTwo d-flex flex-column gap-3'>
                    <div className='d-flex align-items-center justify-content-between p-3 feedTextContainer'>
                        <h6>Your Feed</h6>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_228_10373)">
                                <mask id="mask0_228_10373" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                                    <path d="M39.1125 0.800781H0V39.9133H39.1125V0.800781Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_228_10373)">
                                    <path d="M35.6134 20.3555C35.6134 29.2308 28.4309 36.4123 19.5567 36.4123C10.6815 36.4123 3.5 29.2298 3.5 20.3555C3.5 11.4803 10.6825 4.29883 19.5567 4.29883C28.432 4.29883 35.6134 11.4813 35.6134 20.3555Z" fill="var(--primary-color)" />
                                    <path d="M13.2207 20.5611L17.3456 24.686L25.8248 16.207" stroke="white" stroke-width="2.47026" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_228_10373">
                                    <rect width="39.1125" height="39.1125" fill="white" transform="translate(0 0.800781)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className='p-3 clubContainer'>
                        <h6>Joined Clubs</h6>
                        <div className='row align-items-center px-2'>
                            {clubList?.clubs?.map((item, index) =>
                                <div key={index} className={`${selectedIndex === index ? 'selectedClub' : ''} d-flex flex-column align-items-center col-4 p-2`} onClick={() => onSelectClubs(item, index)}>
                                    <img className='clubImage' src={item?.imageUrl} alt='' />
                                    <p>{item?.userName}</p>
                                    <span className={`${selectedIndex === index ? 'd-block' : 'd-none'}`}>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_228_10373)">
                                                <mask id="mask0_228_10373" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                                                    <path d="M39.1125 0.800781H0V39.9133H39.1125V0.800781Z" fill="white" />
                                                </mask>
                                                <g mask="url(#mask0_228_10373)">
                                                    <path d="M35.6134 20.3555C35.6134 29.2308 28.4309 36.4123 19.5567 36.4123C10.6815 36.4123 3.5 29.2298 3.5 20.3555C3.5 11.4803 10.6825 4.29883 19.5567 4.29883C28.432 4.29883 35.6134 11.4813 35.6134 20.3555Z" fill="var(--primary-color)" />
                                                    <path d="M13.2207 20.5611L17.3456 24.686L25.8248 16.207" stroke="white" stroke-width="2.47026" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_228_10373">
                                                    <rect width="39.1125" height="39.1125" fill="white" transform="translate(0 0.800781)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <Button variant='contained' className='w-50 postSubmit' onClick={() => setStep(1)}>Done</Button>
                </div>
            }
            {successMsg !== "" && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => handleCloseSnackbar()}
                    message={successMsg}
                />
            )}

            {errorMessage !== "" && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => handleCloseSnackbar}
                    message={errorMessage}
                />
            )}
        </div>
    )
}

export default CreateVideo