import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../../context";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../../../auth";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { validate_token } from "../../../core/_request";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { InputBase, Paper } from "@mui/material";
import { handleBrokenImage } from "../../../../lib/utils";
import Cookies from "js-cookie";

const searchPlaceholder = ["an Expert", "a Program", "a Blog"];

function Navbar({ cssClass, onLogout }) {
  const { currentUser, logout, userType, login, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const cookie_token = Cookies.get("authToken")
  const authUserType = Cookies.get("authUserRole");

  // const onHeaderSearchIcon = () => {
  //   window.open(`https://www.unitedwecare.com/search/?query=${searchText}`, '_blank').focus();
  // };

  const onHeaderSearchIcon = () => {
    window.open(`/search/?query=${searchText}`);
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHeaderSearchIcon();
    }
  };

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const search = useLocation().search;

  const token_url = new URLSearchParams(search).get("token");

  // useEffect(() => {
  //   if (!token_url) return;
  //   validate_token(token_url).then((resp) => {
  //     if (resp?.ResponseCode === 200) {
  //       localStorage.setItem("Authorization", resp?.data?.usertoken);

  //       localStorage.setItem(
  //         "profile",
  //         JSON.stringify(resp?.data?.userdetails)
  //       );

  //       let now = new Date();
  //       let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
  //       let firstName = resp?.data?.userdetails?.Name.split(" ")
  //         .slice(0, 1)
  //         .join("");
  //       document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toISOString()};domain=.unitedwecare.com;path=/;`;
  //       document.cookie = `userId=${
  //         currentUser?.UserId
  //       };expires=${oneYearFromNow.toISOString()};domain=.unitedwecare.com;path=/;`;

  //       navigate("/user-dashboard");
  //     }
  //   });
  // }, [token_url]);

  const validateTokenFunction = () => {
    if (cookie_token && !currentUser) {
      validate_token(cookie_token).then((resp) => {
        if (resp?.ResponseCode === 200) {
          login(resp?.data);
          setCurrentUser(resp?.data?.userdetails)

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");
          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
          document.cookie = "userType=expert/user;";
          window.location.reload();
        }
      }).catch((error) => {
        console.error("Error in validate_token with token_url:", error);
      });
    }
  }

  useEffect(() => {
    if (cookie_token && !currentUser) {
      validateTokenFunction();
    }
  }, []);

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder((prevIndex) => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // useEffect(() => {
  //   if (!currentUser) {
  //     // setShowLloginPopup(true);
  //     const currentUrl = encodeURIComponent(window.location.href);
  //     window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  //   }
  // }, [currentUser]);

  const navigateToDashboard = () => {
    debugger
    if (authUserType?.includes('expert')) {
      navigate("/expert/dashboard");
    } else {
      navigate("/user-dashboard");
    }
  };

  return (
    <>
      <Helmet>
        <title>User Dashboard</title>
      </Helmet>

      <nav
        className={`navbar navbar-expand-lg userdashboard--Navbar ${cssClass}`}
      >
        <div className="container-fluid mx-5">
          <div className="d-flex align-items-center ">
            <a className="navbar-brand" href="https://www.unitedwecare.com">
              <img
                alt=""
                src="/assets/img/dashboard/UWC logo.png"
                width={150}
                className="d-block d-sm-none img-fluid"
                style={{ objectFit: "contain" }}
              />
              <img
                alt=""
                src="/assets/img/dashboard/UWC logo.png"
                width="326"
                height="50"
                fill="currentColor"
                className="bi bi-search me-3 d-none d-md-block"
                viewBox="0 0 16 16"
                style={{ objectFit: "contain" }}
              />
            </a>

            {currentUser?.header_image && (
              <img
                src={currentUser?.header_image}
                className="img-fluid"
                style={{
                  width: "110px",
                  height: "65px",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            )}
          </div>
          <div className="d-flex align-items-center border-bottom mx-5 userdashboard--Search w-100">
            <IconButton onClick={onHeaderSearchIcon}>
              <SearchIcon />
            </IconButton>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={handleEnterKeyDown}
              className="me-2 w-100 search_bar"
              type="search"
              placeholder="Search Experts, Programs, Podcast etc..."
              aria-label="Search"
              style={{ border: "none", backgroundColor: "transparent" }}
            />
          </div>

          <div className="userdashboard--Navbar__user d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center mr-4 p-1"
              style={{
                borderRadius: "30px",
                minWidth: "max-content",
              }}
            >
              <img
                src={currentUser?.ProfileImage}
                onError={(e) => {
                  const newLink = handleBrokenImage(currentUser?.ProfileImage);
                  e.target.src = newLink;
                }}
                width={42}
                height={42}
                alt=""
                style={{ borderRadius: "50%", cursor: "pointer" }}
                onClick={navigateToDashboard}
              />
              <span
                style={{
                  fontWeight: "500",
                  marginLeft: "10px",
                  fontFamily: 'Roboto", sans-serif',
                }}
              >
                Hi, {currentUser?.Name.split(" ")[0]}
              </span>
              <IconButton onClick={handleMenuClick}>
                <div className="d-flex align-items-center"></div>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
          <Paper
            component="form"
            className="d-flex d-md-none"
            sx={{
              p: "2px 4px",
              alignItems: "center",
              width: "100%",
              border: "0.5px solid #cccccc",
              borderRadius: "10px",
              boxShadow: "none !important",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={`Search ${searchPlaceholder[changingPlaceholder]}`}
              inputProps={{ "aria-label": "search google maps" }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={handleEnterKeyDown}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => navigate(`/search/?query=${searchText}`)}
            >
              <SearchIcon />
            </IconButton>
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <DirectionsIcon />
                </IconButton> */}
          </Paper>
          {/* <a
            to="https://www.unitedwecare.com/search"
            target="_blank"
            rel="noopener noreferrer"
            onClick={onHeaderSearchIcon}
          >
            <IconButton className="d-none userdashboard--Search__BTN">
              <SearchIcon />
            </IconButton>
          </a> */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
