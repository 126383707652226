import React, { useState, useEffect } from "react";
import AppointmentCard from "../cards/AppointmentCard";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

//Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Text Field
import TextField from "@mui/material/TextField";

import moment from "moment";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

//Switch
import Switch from "@mui/material/Switch";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSnackbar } from "notistack";

//Icons
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import { getToken } from "../APIS/clientAPIS";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

dayjs.extend(localizedFormat);

const AppointmentDefaultView = ({ selectedTab, loading, setLoading }) => {
  const [appointmentList, setAppointmentList] = useState([]);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [clientData, setClientData] = useState({
    slotID: 0,
    clientID: 0,
    clientName: "",
    clientImage: "",
    clientType: "",
    appointmentID: 0,
    appointmentDate: "",
    appointmentTime: "",
    clientStatus: "",
    clientCallingMode: "",
    callDuration: "",
  });

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [prescriptionName, setPrescriptionName] = useState("");
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] =
    useState(false);

  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );
  //console.log("clientData", clientData)
  //Loaders States
  const [isFileLoading, setIsFileLoading] = useState(null);
  const [isCancelled, setIsCancelled] = useState(null);
  const [isPrescriptionAdded, setIsPrescriptionAdded] = useState(null);
  const [isRescheduled, setIsRescheduled] = useState(null);
  const [isNoteAdded, setIsNoteAdded] = useState(null);
  const [arrayLength, setArrayLength] = useState(20);
  const [height, setHeight] = useState(150);
  const [width, setWidth] = useState(300);
  const [columns, setColumns] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  //Error States
  const [prescriptionError, setPrescriptionError] = useState(null);

  const { currentUser } = useAuth();

  //skeleton screen size
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let columns;

      if (screenWidth > 1500) {
        setArrayLength(20);
        columns = 4;
        setWidth(300);
        setHeight(150);
      } else if (screenWidth >= 1200 && screenWidth <= 1500) {
        setArrayLength(20);
        columns = 4;
        setWidth(240);
        setHeight(120);
      } else {
        setArrayLength(10);
        columns = 2;
        setWidth(450);
        setHeight(108);
      }
      setColumns(columns);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const postSlotsData = async () => {
    const payload = {
      user_id: currentUser?.UserId,
      date: moment(clientData?.appointmentDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      const { slots } = await postResponse.data;

      setSlotsList(slots);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Slots List (Reschedule Dialog)
  useEffect(() => {
    if (clientData?.appointmentDate !== "") {
      postSlotsData();
    }
  }, [clientData]);

  const appointmentPayload = {
    expert_id: currentUser?.UserId,
    type: selectedTab.toLowerCase(),
    datetime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  };

  const postAppointmentsData = async () => {
    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(appointmentPayload),
        }
      );
      const postResponse = await response.json();
      const { appointments } = postResponse?.data;

      setAppointmentList(appointments);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Appointments List
  useEffect(() => {
    postAppointmentsData();
  }, [selectedTab]);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  ///Reschedule Region

  //Reschedule
  const handleReschedule = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime,
    clientCallingMode,
    clientStatus,
    callDuration
  ) => {
    // Perform actions to open the reschedule dialog
    // You can access the appointmentID, appointmentDate, and slotID here

    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
      clientCallingMode,
      clientStatus,
      callDuration,
    }));

    handleRescheduleOpen();
  };

  const handleRescheduleOpen = () => {
    setOpenRescheduleModal(true);
  };

  const handleRescheduleClose = () => {
    setOpenRescheduleModal(false);
    setClientData({
      slotID: 0,
      clientID: 0,
      clientName: "",
      clientImage: "",
      clientType: "",
      appointmentID: 0,
      appointmentDate: "",
      appointmentTime: "",
      clientStatus: "",
      clientCallingMode: "",
      callDuration: "",
    });
  };

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setClientData((prevClientData) => ({
      ...prevClientData,
      appointmentDate: formattedDate,
    }));
  };

  const handleRescheduleAppointment = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    setIsRescheduled(false);
    const payload = {
      AppointmentId: `${clientData?.appointmentID}`,
      ExpertId: currentUser?.UserId,
      AppointmentDates: clientData?.appointmentDate,
      App_StartTime: clientData?.appointmentTime,
      App_EndTime: dayjs(clientData?.appointmentTime, "hh:mm A")
        .add(clientData?.callDuration.split(" ")[0], "minutes")
        .format("hh:mm A"),
      CallMode: "Video_Call",
      Status: "Booked",
    };
    let sessionBearerToken = sessionStorage.getItem("token");

    const rescheduleHeaders = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "web",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/UpdateAppointmentByBackend`,
        {
          method: "POST",
          headers: rescheduleHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.ResponseCode;

      if (reponseCode == 200) {
        setSnackbarMessage("Resheduled successfully!");
        setOpenSnackbar(true);
        handleRescheduleClose();
        setIsRescheduled(true);
        postAppointmentsData();
      } else if (reponseCode == 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res.accessToken);
          setTimeout(() => handleRescheduleAppointment(retryCount + 1), 0);
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Slots Section

  const handleSlotChange = (slot) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      slotID: slot.id,
      appointmentTime: slot?.slot.split(" - ")[0],
    }));
  };

  ///Reschedule Region End

  ///Notes Region

  const handleNotes = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime,
    clientID
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
      clientID,
    }));
    handleNotesOpen();
  };

  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const handlePostNote = async () => {
    setIsNoteAdded(false);
    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: clientData?.clientID,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  ///Notes Region End

  ///Prescription Region

  const handlePrescription = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));
    setPrescriptionName(clientName);
    setPrescriptionNote(appointmentDate);
    setSelectedFiles("");
    setUploadProgress(0);
    handlePrescriptionOpen();
  };

  const handlePrescriptionOpen = () => {
    setOpenPrescriptionModal(true);
  };

  const handlePrescriptionClose = () => {
    setOpenPrescriptionModal(false);
  };

  const handlePrescriptionName = (e) => {
    setPrescriptionName(e.target.value);
  };

  const handlePrescriptionNote = (e) => {
    setPrescriptionNote(e.target.value);
  };

  const handlePostPrescription = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    setIsPrescriptionAdded(false);

    const payload = {
      AppointmentId: +clientData?.appointmentID,
      Title: prescriptionName,
      Note: prescriptionNote,
      DocsFile: selectedFiles,
    };

    let sessionBearerToken = sessionStorage.getItem("token");

    const prescriptionHeaders = {
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/saveAppointment_Documents_Files`,
        {
          method: "POST",
          headers: prescriptionHeaders,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();
      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setPrescriptionName("");
        setPrescriptionNote("");
        setSelectedFiles("");
        handlePrescriptionClose();
        setSnackbarMessage("Prescription sent successfully!");
        setOpenSnackbar(true);
        setIsPrescriptionAdded(true);
      } else if (postResponse?.ResponseCode == 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res.accessToken);
          setTimeout(() => handlePostPrescription(retryCount + 1), 0);
        });
      } else {
        console.log(postResponse?.ResponseMessage);
        setIsPrescriptionAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    if (file.size > 3 * 1024 * 1024) {
      alert("File size exceeds 2MB limit.");
      return;
    }

    if (file?.type.startsWith("image/") || file?.type === "application/pdf") {
      setIsFileLoading(true);
      setUploadProgress(0);
      let dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(percent);
          console.log("uploading", percent);
        },
        (err) => {
          enqueueSnackbar(err, { variant: "error" });
          setPrescriptionError(err);
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setSelectedFiles(url);
            setIsFileLoading(false);
          });
        }
      );
    } else {
      console.error("Incorrect file format.");
    }
    // setIsFileLoading(false);
  };
  ///Prescription Region End

  ///Cancel Region

  const handleCancel = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    setIsCancelled(false);
    const payload = {
      AppointmentId: clientData?.appointmentID,
    };
    let sessionBearerToken = sessionStorage.getItem("token");

    const cancelHeaders = {
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "web",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/CancelAppointment`,
        {
          method: "POST",
          headers: cancelHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        handleCancelConfirmationClose();
        setSnackbarMessage("Cancelled successfully!");
        setOpenSnackbar(true);
        setIsCancelled(true);
        postAppointmentsData();
      } else if (message == 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res?.accessToken);
          setTimeout(() => handleCancel(retryCount + 1), 0);
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelConfirmationOpen = () => {
    setOpenCancelConfirmationModal(true);
  };

  const handleCancelConfirmationClose = () => {
    setOpenCancelConfirmationModal(false);
  };

  const handleCancelAppointment = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handleCancelConfirmationOpen();
  };

  ///Cancel Region End
  const filteredSlotsData = slotsList?.filter((item) => {
    if (!checked) {
      // Render morning slots (AM)
      return item.slot.split(" - ")[0].includes("AM");
    } else {
      // Render evening slots (PM)
      return item.slot.split(" - ")[0].includes("PM");
    }
  });

  const cancelledAppointments = appointmentList?.filter(
    (item) => item.Status == "Cancelled"
  );
  const openAppointments = appointmentList?.filter(
    (item) => item.Status !== "Cancelled"
  );

  //console.log("setIsFileLoading", isFileLoading)

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#FFF",
          padding: "10px 10px 26px 10px",
          marginTop: "20px",
          marginLeft: 0,
          marginRight: 0,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${columns}, 1fr)`,
              gap: "15px",
              justifyContent: "center",
              padding: "10px",
              width: "100%",
            }}
          >
            {Array.from(new Array(arrayLength)).map((_, index) => (
              <Grid
                item
                xs={6}
                lg={3}
                sx={{
                  minWidth: "100%",
                  backgroundColor: "rgb(0,0,0,0.07)",
                  padding: "10px",
                  height: "168px",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex gap-2 Card--Top my-1 p-1">
                  <Skeleton variant="circular" width={45} height={45} />

                  <div>
                    <Skeleton
                      variant="text"
                      width="8vw"
                      height={20}
                      sx={{ borderRadius: "5px" }}
                    />
                    <p>
                      <Skeleton
                        variant="text"
                        width="5vw"
                        height={20}
                        sx={{ borderRadius: "5px" }}
                      />
                    </p>
                  </div>
                </div>

                <div
                  className="Card--Timings d-flex gap-4 p-2 w-100"
                  style={{ margin: "10px 0" }}
                >
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={20}
                    sx={{ borderRadius: "5px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={20}
                    sx={{ borderRadius: "5px" }}
                  />
                </div>
                <div
                  className="d-flex gap-2 w-100"
                  style={{ margin: "10px 0" }}
                >
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={34}
                    sx={{ borderRadius: "20px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={34}
                    sx={{ borderRadius: "20px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="10%"
                    height={40}
                    sx={{ borderRadius: "20px" }}
                  />
                </div>
              </Grid>
            ))}
          </div>
        ) : appointmentList?.length === 0 ? (
          <div className="NoDataAvailable">
            <img src="/assets/img/no-appointment.png" />
            <p>No Appointment data available</p>
          </div>
        ) : (
          <Grid container spacing={2}>
            {appointmentList.map((item, index) => (
              <Grid item xs={6} lg={3} key={item.id}>
                <AppointmentCard
                  id={item.ID}
                  clientID={item.UserId}
                  img={item.ProfileImage}
                  name={item.Name}
                  clientType={item.client_type}
                  appointmentID={item.ID}
                  appointmentDate={item.AppDate}
                  appointmentTime={item.AppTime}
                  joinURL={item.join_url}
                  status={item.Status}
                  selectedTab={selectedTab}
                  duration={item.duration}
                  onReschedule={() =>
                    handleReschedule(
                      item.Name,
                      item.ProfileImage,
                      item.client_type,
                      item.ID,
                      item.AppDate,
                      item.AppTime,
                      item.CallingMode,
                      item.Status,
                      item.duration
                    )
                  }
                  onAddNote={() =>
                    handleNotes(
                      item.Name,
                      item.ProfileImage,
                      item.client_type,
                      item.ID,
                      item.AppDate,
                      item.AppTime,
                      item.UserId,
                      item.Status
                    )
                  }
                  onPrescription={() =>
                    handlePrescription(
                      item.Name,
                      item.ProfileImage,
                      item.client_type,
                      item.ID,
                      item.AppDate,
                      item.AppTime
                    )
                  }
                  onCancel={() =>
                    handleCancelAppointment(
                      item.Name,
                      item.ProfileImage,
                      item.client_type,
                      item.ID,
                      item.AppDate,
                      item.AppTime
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isNoteAdded !== null && !isNoteAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Reschedule Dialog */}
      <Dialog
        className="Dialog Reschedule--Dialog"
        open={openRescheduleModal}
        onClose={handleRescheduleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isRescheduled !== null && !isRescheduled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Reschedule Appointment </DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <div>
            <h4>Reschedule to</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To date"
                format="DD/MM/YYYY"
                value={dayjs(clientData?.appointmentDate, "DD/MM/YYYY")}
                onChange={onToDateChange}
                disablePast={true}
                sx={{
                  width: "100%",
                  marginBottom: "8px",
                  backgroundColor: "#FFF",
                }}
              />
            </LocalizationProvider>
          </div>

          <div>
            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0" }}
              className="Timings"
            >
              {slotsList?.length > 0 ? (
                slotsList?.map((item) => (
                  <Grid
                    item
                    xs={2}
                    key={item.slot}
                    className={`AvailableSlot ${
                      item.id == clientData?.slotID ? "SelectedSlot" : ""
                    }`}
                    onClick={() => handleSlotChange(item)}
                  >
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                ))
              ) : (
                <p className="mx-auto" style={{ fontWeight: "500" }}>
                  No slots available for selected dates
                </p>
              )}
            </Grid>
          </div>

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "62px auto 20px auto" }}
            onClick={handleRescheduleAppointment}
            disabled={clientData.appointmentDate === "" ? true : false}
          >
            Confirm and Reschedule
          </Button>
        </DialogContent>
      </Dialog>

      {/* Precriptions Dialog */}
      <Dialog
        className="Dialog Prescription--Dialog"
        open={openPrescriptionModal}
        onClose={handlePrescriptionClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isPrescriptionAdded !== null && !isPrescriptionAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Prescription</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Prescription Name"
            value={prescriptionName}
            onChange={handlePrescriptionName}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Prescription notes"
            value={prescriptionNote}
            onChange={handlePrescriptionNote}
            multiline
            rows={4}
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          />
          {(isFileLoading || uploadProgress > 0) && (
            <Box sx={{ width: "100%", margin: "10px 0", borderRadius: "10px" }}>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                color={uploadProgress === 100 ? "success" : "secondary"}
              />
            </Box>
          )}

          <span style={{ fontSize: "12px", color: "gray", fontWeight: "600" }}>
            Only .jpg, .jpeg, .png, .pdf allowed up to 2mb
          </span>

          <div
            className="upload-button"
            style={{ marginTop: "0", height: "100%" }}
          >
            <label
              htmlFor="file-upload"
              className="upload-button-label"
              style={{ height: "100%", fontSize: "16px" }}
            >
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload
            </label>

            {isFileLoading && uploadProgress >= 0 && uploadProgress < 100 ? (
              <div className="d-flex align-items-center mx-auto">
                <CircularProgress
                  sx={{ width: "20px !important", height: "20px !important" }}
                />
              </div>
            ) : (
              <>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  className="upload-button-input"
                />
                {selectedFiles && (
                  <div className="selected-files">
                    <div className="file-name">{selectedFiles}</div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="d-grid">
            {prescriptionError && (
              <span
                style={{ fontSize: "12px", color: "red", fontWeight: "600" }}
              >
                {prescriptionError}
              </span>
            )}
          </div>

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostPrescription}
            disabled={
              prescriptionNote == "" ||
              prescriptionName == "" ||
              selectedFiles === ""
                ? true
                : false
            }
          >
            Add Prescription
          </Button>
        </DialogContent>
      </Dialog>

      {/* Cancel Dialog */}
      <Dialog
        className="Dialog Cancel--Dialog"
        open={openCancelConfirmationModal}
        onClose={handleCancelConfirmationClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isCancelled !== null && !isCancelled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogContent className="Dialog--Content">
          <WarningRoundedIcon />
          <h6>
            Are you Sure want to cancel this Appointment? This action cannot be
            undone
          </h6>
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <div className="CTA--Container d-flex align-items-center gap-3 mt-3">
            <Button
              className="PrimaryCTA--Outlined"
              variant="contained"
              disableElevation
              onClick={handleCancelConfirmationClose}
            >
              No, Take me back
            </Button>

            <Button
              className="PrimaryCTA"
              variant="contained"
              disableElevation
              onClick={handleCancel}
            >
              Yes, Cancel it
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppointmentDefaultView;
