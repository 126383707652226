import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DetailAboutTab from "./DetailAboutTab";
import DetailTestimonialTab from "./DetailTestimonialTab";
import DetailPostTab from "./DetailPostTab";
import DetailArticlesTab from "./DetailArticlesTab";
import DetailPodcastsTab from "./DetailPodcastsTab";
import { useAuth } from "../auth";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { OODOGetUserFeedV4Service, getExpertRating } from "./core/_request";
import "./ExpertFilterDrawer.css";
import { Skeleton } from "@mui/material";
import { Odoo_community_feed } from "./Community/_request";
import VideoCard from "./BookingJourney/Component/VideoCard";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import BlogCard from "./BookingJourney/Component/BlogCard";

const DetailPageTabs = (props) => {
  const { data, loading } = props;
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { profession, expertName, id } = useParams();
  const location = useLocation();
  const [articleData, setArticleData] = useState();
  const [videoData, setVideoData] = useState();
  const [audioData, setAudioData] = useState();
  const [ratingData, setRatingData] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState("about");
  var ExpertisesArrayAll = data?.Expertise?.split(",");
  const PAGE_SIZE = 6;
  const [postData, setPostData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    getExpertRating(data?.Id).then((resp) => {
      var data = resp?.data;
      setRatingData(data);
    });
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let pageFromUrl = queryParams.get("p");
    let tabFromUrl = queryParams.get("t");

    // Handle null or undefined page from URL
    const newPage = pageFromUrl ? parseInt(pageFromUrl, 10) : 1;
    const newTab = tabFromUrl || "about";

    if (newPage !== page || newTab !== defaultActiveKey) {
      setPage(newPage);
      setDefaultActiveKey(newTab);
    }

    // Navigate only if necessary
    if (pageFromUrl !== String(page) || tabFromUrl !== defaultActiveKey) {
      navigate(
        `/profession/${profession}/${expertName}/${id}?t=${newTab}&p=${newPage}`
      );
    }
  }, [location.search]); // Only rerun this effect if the search part of the URL changes

  useEffect(() => {
    fetchData_Post();
  }, [page, defaultActiveKey]);

  const handlePageChange = (event, newPage) => {
    const adjustedPage = newPage + 1; // Adjust the page number to match the 1-based index
    setPage(adjustedPage); // Update the page state
    navigate(
      `/profession/${profession}/${expertName}/${id}?t=${currentTab}&p=${adjustedPage}`
    );
  };

  function fetchData_Post() {
    setIsLoading(true);
    setCurrentTab(defaultActiveKey);

    let body = {
      posttype: defaultActiveKey,
      page: page - 1, // Adjusted the page number
      count: PAGE_SIZE,
      sUserId: data?.Id,
    };

    // Fetch data from the API with updated page number
    if (defaultActiveKey == "article" || defaultActiveKey == "video") {
      Odoo_community_feed(body)
        .then((resp) => {
          if (resp && resp?.feed) {
            setTotalCount(resp?.total_count);
            if (defaultActiveKey == "video") {
              setPostData(resp?.feed);
            } else if (defaultActiveKey == "article") {
              setArticleData(resp?.feed);
            }
          } else {
            console.error("Invalid response format:", resp);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    }
  }

  const onTabClick = (activeKey) => {
    setIsLoading(true)
    setDefaultActiveKey(activeKey);
    if (activeKey === "video" || activeKey === "article") {
      setCurrentTab(activeKey);
      setPage(1);
      navigate(
        `/profession/${profession}/${expertName}/${id}?t=${activeKey}&p=1`
      );
    } else if (activeKey === "about") {
      navigate(`/profession/${profession}/${expertName}/${id}?t=${activeKey}`);
    }
    // if (!currentUser) {
    //     navigate("/login?retrunurl=/detail/" + data?.Id);
    // }
  };

  // useState(() => {
  //   if (currentUser && data) {
  //     OODOGetUserFeedV4Service(currentUser?.UserId, data?.Id, "", "", "post", "", 0, 10, "id desc").then((resp) => {
  //       setPostData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(currentUser?.UserId, data?.Id, "", "", "article", "", 0, 10, "id desc").then((resp) => {
  //       setArticleData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(currentUser?.UserId, data?.Id, "", "", "video", "", 0, 10, "id desc").then((resp) => {
  //       setVideoData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(currentUser?.UserId, data?.Id, "", "", "audio", "", 0, 10, "id desc").then((resp) => {
  //       setAudioData(resp?.data);
  //     });
  //   }
  // }, []);

  return (
    <div className="bg-white detailPage--Container rounded-3">
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={defaultActiveKey}
        id="controlled-tab-example"
        className="text-black"
        style={{ backgroundColor: "#F7F8FA", maxWidth: "max-content" }}
        onSelect={onTabClick}
      >
        <Tab eventKey="about" title="About" className="px-3 tab-heading py-2">
          {(data && (
            <DetailAboutTab
              data={{ ...data, ratingData: ratingData }}
              changeTab={onTabClick}
              loading={loading}
            />
          )) ||
            null}
        </Tab>
        <Tab eventKey="video" title="Video" className="px-3 tab-heading py-2">
          {(isLoading && (
            <div
              className="dashboardCourses--Container p-2 gap-3"
              style={{
                maxWidth: "1440px",
                margin: "auto",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              {Array.from(new Array(12)).map((_, index) => (
                <div
                  key={index}
                  className="dashboardCourse--Item rounded-4 px-3 py-3 d-flex flex-column justify-content-between gap-3"
                  style={{
                    border: "1px solid rgb(0,0,0,0.07)",
                    height: "100%",
                    backgroundColor: "rgb(0,0,0,0.07)",
                  }}
                >
                  <div className="text-decoration-none d-flex flex-column gap-1">
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="150px"
                      className="rounded rounded-3"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="25px"
                      className="rounded rounded-3 mt-1"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="25px"
                      className="rounded rounded-3 mt-2"
                    />
                  </div>
                </div>
              ))}
            </div>
          )) ||
            (postData?.length > 0 && (
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="row">
                  {postData?.map((item, index) => (
                    <div className="col-md-6 col-xl-4 p-2" key={index}>
                      <VideoCard item={item?.data} />
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-wrap flex-md-row gap-3 gap-md-0 align-items-center justify-content-end mt-4">
                  {/* <p style={{ fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: 'normal' }}>Showing {page * 6 - 5}-{page * 6} of {totalCount} videos</p> */}
                  {/* <Pagination page={2} count={Math.ceil(totalCount / PAGE_SIZE)} variant="outlined" shape="rounded" className="videoPagination" onChange={handlePageChange} /> */}
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    rowsPerPage={6}
                    rowsPerPageOptions={[]} // Set rowsPerPageOptions to an empty array
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )) || (
              <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center mt-5 mt-md-0">
                <img
                  src="/assets/img/kareify/videoPage.png"
                  alt=""
                  className="d-none d-md-block"
                />
                <img
                  src="/assets/img/kareify/videoPage_m.png"
                  alt=""
                  className="d-block d-md-none mb-2"
                />
                <h6
                  style={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "17px",
                  }}
                >
                  No Video is uploaded by this expert
                </h6>
              </div>
            )}
        </Tab>
        <Tab
          eventKey="article"
          title="Articles"
          className="px-3 tab-heading py-2"
        >
          {(isLoading && (
            <div
              className="dashboardCourses--Container p-2 gap-3"
              style={{
                maxWidth: "1440px",
                margin: "auto",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "16px",
              }}
            >
              {Array.from(new Array(12)).map((_, index) => (
                <div
                  key={index}
                  className="dashboardCourse--Item rounded-4 px-3 py-3 d-flex flex-column justify-content-between gap-3"
                  style={{
                    border: "1px solid rgb(0,0,0,0.07)",
                    height: "100%",
                    backgroundColor: "rgb(0,0,0,0.07)",
                  }}
                >
                  <div className="text-decoration-none d-flex flex-column gap-1">
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="150px"
                      className="rounded rounded-3"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="25px"
                      className="rounded rounded-3 mt-1"
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="25px"
                      className="rounded rounded-3 mt-2"
                    />
                  </div>
                </div>
              ))}
            </div>
          )) ||
            (articleData?.length > 0 && (
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="row">
                  {articleData?.map((item, index) => (
                    <div className="col-md-4 p-2" key={index}>
                      <BlogCard item={item?.data} />
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-wrap flex-md-row gap-3 gap-md-0 align-items-center justify-content-end mt-4">
                  {/* <p style={{ fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: 'normal' }}>Showing {page * 6 - 5}-{page * 6} of {totalCount} videos</p> */}
                  {/* <Pagination page={2} count={Math.ceil(totalCount / PAGE_SIZE)} variant="outlined" shape="rounded" className="videoPagination" onChange={handlePageChange} /> */}
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page - 1} // Adjusted the page number
                    onPageChange={handlePageChange}
                    rowsPerPage={6}
                    rowsPerPageOptions={[]} // Set rowsPerPageOptions to an empty array
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )) || (
              <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center mt-5 mt-md-0">
                <img
                  src="/assets/img/kareify/videoPage.png"
                  alt=""
                  className="d-none d-md-block"
                />
                <img
                  src="/assets/img/kareify/videoPage_m.png"
                  alt=""
                  className="d-block d-md-none mb-2"
                />
                <h6
                  style={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "17px",
                  }}
                >
                  No Article is uploaded by this expert
                </h6>
              </div>
            )}
        </Tab>
        {/* <Tab eventKey="testimonial" title="Testimonial" className="px-3 tab-heading py-3">
                    <DetailTestimonialTab />
                </Tab> */}
        {/* {postData && postData?.feed && postData?.feed?.length > 0 && <Tab eventKey="post" title="Post" className="px-3 tab-heading py-3">
                    <DetailPostTab data={postData} type="post" />
                </Tab>}
                {articleData && articleData?.feed && articleData?.feed?.length > 0 && <Tab eventKey="articles" title="Articles" className="px-3 tab-heading py-3" >
                    <DetailArticlesTab data={articleData} type="article" />
                </Tab>}
                {videoData && videoData?.feed && videoData?.feed?.length > 0 && <Tab eventKey="videos" title="Videos" className="px-3 tab-heading py-3" >
                    <DetailPostTab data={videoData} type="video" />
                </Tab>}
                {audioData && audioData?.feed && audioData?.feed?.length > 0 && <Tab eventKey="podcasts" title="Podcasts" className="px-3 tab-heading py-3" >
                    <DetailPodcastsTab data={audioData} type="audio" />
                </Tab>} */}

        {/* {ExpertisesArrayAll.length >= 1 ?
                    <Tab eventKey="expertise" title="Area of Interest" className="px-3 tab-heading py-3" >
                        <p className='font-inter fs-5 font-w500 my-3 text-black d-flex align-items-center flex-wrap'>
                            {
                                ExpertisesArrayAll && ExpertisesArrayAll.map((Expertise) => (
                                    <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1' style={{ border: "1px solid #60B4E7CC", width: "auto" }}> {Expertise}</span>
                                ))
                            }
                        </p>
                    </Tab>
                    :
                    null
                } */}

        {ratingData?.length >= 1 && (
          <Tab
            eventKey="reviews"
            title="Reviews"
            className="px-3 tab-heading py-3"
          >
            {!loading ? (
              ratingData &&
              ratingData?.map((rating, index) => (
                <div className="my-3" key={index}>
                  <div className="d-flex align-items-center gap-3 my-3">
                    <img src="/assets/img/Ellipse 1.png" alt="" />
                    <p className="font-inter fs-6 font-w500 text-black text-capitalize lh-sm">
                      {rating?.UserName} <br />
                      <span className="font-inter fs-6 font-w400">
                        {rating?.UserCountry}
                      </span>
                    </p>
                  </div>
                  <p className="font-inter fs-6">{rating?.Message}</p>
                  <hr />
                </div>
              ))
            ) : (
              <div>
                <div className="d-flex flex-column gap-1">
                  {Array.from(new Array(ratingData?.length)).map((_, index) => (
                    <div key={index}>
                      <div className="d-flex flex-row align-items-center gap-3 my-1">
                        <Skeleton variant="circular" width={50} height={50} />
                        <p className="font-inter fs-6 font-w500 text-black text-capitalize lh-sm">
                          <Skeleton variant="text" width="5vw" />
                          <span className="font-inter fs-6 font-w400">
                            <Skeleton variant="text" width="5vw" />
                          </span>
                        </p>
                      </div>
                      <p className="font-inter fs-6">
                        <Skeleton variant="text" width="40vw" />
                        <Skeleton variant="text" width="40vw" />
                      </p>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default DetailPageTabs;
