import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    getExpertbyId_WebService,
    AddAppointmentUserService,
    UserDetailsService,
    updatePhoneNumber,
    isFirstTimeAppointment,
    RescheduledService,
    getInstituteDiscountOODOService,
    AddUserPackageService,
    AddTransactionService,
    UpdateTransactionService,
    Get_Appointment_Detailsbyid,
    IndianpackagewithpriceService,
} from './core/_request';
import { useAuth } from '../auth';
import { getCountryCode, getCurrencyCode } from './core/commonFun';
import { AppContext } from '../context';
import { useSnackbar } from 'notistack';
import AlertPopup from './courseInternalJourney/components/AlertPopup';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { handleBrokenImage } from '../lib/utils';

const modalData = {
    title: "Please update your Mobile Number !",
    desc: "For a streamlined booking experience, please input your Mobile Number. We may contact you if necessary.",
};

const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
    });
}

const ExpertBookingPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const appid = new URLSearchParams(search).get("appid");

    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState("");
    const [expertDetailsData, setExpertDetailsData] = useState();
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [isValid, setValid] = useState(false);
    const [data, setData] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showScreenerModal, setShowScreenerModal] = useState(false);

    const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];
    const { currentUser, updateMobileNo } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const { setShowLloginPopup } = useContext(AppContext);

    const mobileNumberRegex = /^[6-9][0-9]{9}$/;

    useEffect(() => {
        const fetchData = async () => {
            const resp = await getExpertbyId_WebService(id);
            const data1 = resp.data;
            data1["countryAmount"] = countryCode === "IN" ? data1.Hourly_Consultaion_Fee_amount : data1.USD_Amount;
            setExpertDetailsData(data1);

            let tdata;
            if (appid) {
                tdata = await getAppDataByAppId();
            } else {
                tdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"));
            }
            const mainAmount = tdata.callTime === 30 ? (data1.countryAmount / 2) : data1.countryAmount;
            tdata["mainAmount"] = mainAmount;
            setData(tdata);
        };

        fetchData();
    }, [appid, id, countryCode]);

    useEffect(() => {
        if (currentUser?.MobileNo) {
            const savedPhoneNumber = currentUser?.MobileNo.replace(/^\+91|^\+00/, "").trim();
            setValid(mobileNumberRegex.test(savedPhoneNumber));
        }
    }, [currentUser?.MobileNo]);

    useEffect(() => {
        if (number) {
            setValid(mobileNumberRegex.test(number));
        }
    }, [number]);

    const handleCloseScreenerConfirmation = () => {
        navigate("/user-dashboard");
        setShowScreenerModal(false);
    };

    const handleMobileNumberChange = (e) => {
        const inputNumber = e.target.value;
        setNumber(inputNumber);
    };

    const onCheckout = () => {
        setCheckoutLoading(true);
        if (!number || number === "0000000000") {
            if (!currentUser?.MobileNo || currentUser?.MobileNo === "+91 0000000000" || currentUser?.MobileNo === "+00 0000000000") {
                setShowModal(true);
                setCheckoutLoading(false);
            } else {
                handleAppointmentBooking();
            }
        } else if (isValid) {
            handleMobileUpdateAndAppointment();
        } else {
            enqueueSnackbar("Invalid Mobile Number", { variant: "warning" });
            setCheckoutLoading(false);
        }
    };

    const handleMobileUpdateAndAppointment = () => {
        const vbody = { MobileNo: number };
        updatePhoneNumber(vbody).then(resp => {
            if (resp.ResponseCode === 200) {
                updateMobileNo(number);
                handleAppointmentBooking();
            } else {
                enqueueSnackbar("Wrong Mobile Number", { variant: "warning" });
                setCheckoutLoading(false);
            }
        });
    };

    const handleAppointmentBooking = () => {
        setLoading(true);
        const body = {
            ExpertId: id,
            AppointmentDate: moment(data.selectedDate).format("YYYY-MM-DD"),
            App_StartTime: data.selectSlot.Time_Duration,
            CallMode: "Video_Call",
            CallTime: data.callTime,
        };
        const AppointmentId = new URLSearchParams(search).get("AppointmentId");
        if (AppointmentId) {
            body.AppointmentId = AppointmentId;
            RescheduledService(body).then(resp => {
                enqueueSnackbar("Appointment Successfully Rescheduled!", { variant: "success" });
                navigate(`/appointment-confirmed/${id}`);
                setLoading(false);
            });
        } else {
            AddAppointmentUserService(body).then(async resp => {
                if (resp.ResponseCode >= 200 && resp.ResponseCode < 300) {
                    const disAmount = await getInstituteDiscountOODOService(expertDetailsData?.countryAmount).then(resp => resp.data);
                    const TotalAmount = Math.round(disAmount ?? data.mainAmount);
                    const UserDetails = await UserDetailsService().then(resp => resp.data);
                    const tbody = {
                        ExpertId: id,
                        PackageName: "Pay As You go",
                        Country: currentUser.Country,
                        Type: "Mental Health and Wellness Coach",
                        HSTAmount: 0,
                        AppointmentCount: 0,
                        DurationInMonths: 0,
                        Amount: TotalAmount,
                    };
                    AddUserPackageService(tbody).then(resp => {
                        addTrans(null, resp.data, TotalAmount, UserDetails, data.data2);
                    });
                } else {
                    console.log(resp.ResponseMessage);
                    setLoading(false);
                }
            });
        }
    };

    const addTrans = (response, AddUserPackageData, TotalAmount, UserDetails, Indianpackagewithprice) => {
        const rendId = `${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}_web`;
        const body = {
            ExpertId: id,
            PackageId: AddUserPackageData.PackageId,
            TransactionId: rendId,
            Amount: TotalAmount,
            isMarkAutoRenew: false,
            TransactionType: "Credit Card",
            PaymentGateway_OrderId: "",
            Billing_Address_Master: {
                TransactionId: rendId,
                Name: UserDetails.Name,
                Email: UserDetails.Emailid,
                Mobile: UserDetails.MobileNo,
            },
            DiscountAmount: (countryCode === "IN" ? (Indianpackagewithprice.Amount - TotalAmount) : 0),
            PaymentGateway_OrderId: response?.razorpay_payment_id,
        };
        AddTransactionService(body).then(() => {
            const tbody = { TransactionId: rendId, Status: "Completed" };
            UpdateTransactionService(tbody).then(() => {
                localStorage.removeItem("dispr");
                setCheckoutLoading(false);
                isFirstTimeAppointment().then(resp => {
                    if (resp.data.Appointment_count === 1) {
                        enqueueSnackbar("Appointment Successfully Booked!", { variant: "success" });
                        navigate(`/appointment-confirmed/${id}`);
                        setLoading(false);
                    } else {
                        enqueueSnackbar("Appointment Successfully Booked!", { variant: "success" });
                        navigate(`/appointment-confirmed/${id}`);
                        setLoading(false);
                    }
                });
            });
        });
    };

    const getAppDataByAppId = async () => {
        if (!currentUser) {
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        }
        const outData = {};
        await IndianpackagewithpriceService(id).then(resp => (outData["data2"] = resp.data));
        await Get_Appointment_Detailsbyid(appid).then(resp => {
            outData["selectedDate"] = resp.data.AppointmentDate;
            outData["selectSlot"] = { Time_Duration: resp.data.App_StartTime };
            outData["callTime"] = resp.data.Minutes;
        });
        return outData;
    };

    const onClickNavigate = () => {
        navigate(`/book-an-appointment/${id}`);
    };

    return (
        <>
            {!expertDetailsData ?
                <div className="d-flex align-items-center justify-content-center mx-auto px-2 px-md-5" style={{ height: "100%", width: "100vw", background: "#FFF", paddingBottom: "100px" }}>
                    <div className='w-100'>
                        <Skeleton variant="rounded-1" width="30%" height={40} className="mx-auto rounded-3 my-4" />
                        <div className="col-md-7 mx-auto OrderPreview--Skeleton">
                            <div className='d-flex sm:flex-col gap-3' style={{ width: "100%", height: "300px", backgroundColor: "rgba(0, 0, 0, 0.09)", borderRadius: "20px", marginTop: "10px" }}>
                                <div className='OrderPreview--Skeleton__Left' style={{ padding: "45px 20px", display: "flex", flexDirection: "row", gap: "20px" }}>
                                    <Skeleton variant="rounded" width={130} height={130} />
                                    <div className='flex-1'>
                                        {Array.from(new Array(4)).map((_, index) => (
                                            <Skeleton key={index} variant="text" width={`${100 - (index * 10)}%`} height={30} />
                                        ))}
                                    </div>
                                </div>
                                <div className='OrderPreview--Skeleton__Right' style={{ padding: "45px 20px" }}>
                                    <Skeleton variant="rounded" width={250} height={130} />
                                    <div className='mt-3'>
                                        {Array.from(new Array(3)).map((_, index) => (
                                            <Skeleton key={index} variant="text" width={`${100 - (index * 10)}%`} height={30} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='d-block pb-2' style={{ background: "#F7F2FF", minHeight: "100vh" }}>
                    <div className='col-md-7 mx-auto px-2 px-md-5 rounded-3'>
                        <h5 className='font-w600 text-center fs-2 py-3'>Review Session Details</h5>
                        <div className='bg-white rounded-2'>
                            <div className='row'>
                                <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: '#fff' }}>
                                    <div className="row align-items-center">
                                        <img
                                            src={expertDetailsData?.ProfileImage}
                                            onError={(e) => {
                                                const newLink = handleBrokenImage(expertDetailsData?.ProfileImage);
                                                e.target.src = newLink
                                            }}
                                            className='col-5 col-md-6 rounded-5 p-4' alt=''
                                        />
                                        <div className='col-6'>
                                            <p className='font-w600 fs-6 font-inter lh-base text-black'>{expertDetailsData?.Name}</p>
                                            <p className='font-inter fs-6 lh-base'>{expertDetailsData?.RegisterYourselfAs}</p>
                                            <p className='font-inter fs-6 lh-base'>Years of Experience: {expertDetailsData?.Experience}</p>
                                            <p className='font-inter fs-6 lh-base'>{expertDetailsData?.Qualifications}</p>
                                            <p className='font-inter fs-6 lh-base'><img src='/assets/img/flag.png' alt='' /> India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: '#fff' }}>
                                    <div className='rounded-2 m-3 p-3 d-flex justify-content-between align-items-center' style={{ background: "#CCB6EC", border: "1px solid #CCB6EC" }}>
                                        <div>
                                            <p className='font-inter text-black font-w600 fs-5'>Video Session</p>
                                            <p className='font-inter text-black font-w400 fs-6'>{moment(data.selectedDate).format("DD MMMM")}</p>
                                            <p className='font-inter text-black font-w400 fs-6'>{data.selectSlot.Time_Duration} (IST)</p>
                                            <p className='font-inter text-black font-w400 fs-6'>{data.callTime} Min Individual Session</p>
                                        </div>
                                        <div>
                                            <button onClick={onClickNavigate} className='border-0 bg-transparent'>
                                                <img src='/assets/img/rename_icon.png' alt='' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='mx-2 mx-md-4 mt-4'>
                                        <h5 className='font-inter d-flex justify-content-between' style={{ justifyContent: 'center' }}>Bill To
                                            <span style={{ height: 1, width: '75%', backgroundColor: '#E2E2E1', alignSelf: 'center' }} />
                                        </h5>
                                        <p className='font-inter font-w400 fs-6 d-flex mt-1 justify-content-between'>Name:
                                            <span className='font-inter font-w500 fs-6 text-black'>{currentUser?.Name}</span>
                                        </p>
                                        <p className='font-inter font-w400 fs-6 d-flex mt-1 justify-content-between'>Email:
                                            <span className='font-inter font-w500 fs-6 text-black' style={{ overflow: 'hidden' }}>{currentUser?.Emailid}</span>
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-between justify-content-md-between m-2 mt-2 mx-md-4 rounded-2' style={{ border: "1px solid #D9D9D9" }}>
                                        <input
                                            className='rounded-2 border-0'
                                            type='tel'
                                            maxLength={10}
                                            placeholder={currentUser?.MobileNo === "+91 0000000000" ? "Phone Number(10 digits) *" : currentUser?.MobileNo.replace(/^\+00/, "")}
                                            value={number}
                                            onChange={handleMobileNumberChange}
                                            style={{ height: "45px", width: '100%', paddingLeft: 7 }} />
                                    </div>
                                    <span className="m-2 mt-2 mx-md-4 font-inter font-w400" style={{ fontSize: "0.80rem" }}>Mobile Number is Mandatory <span className="font-w600">*</span></span>
                                </div>
                            </div>
                        </div>
                        <Button
                            disabled={loading || !isValid}
                            id='professional_complete_payment'
                            className="col-11 col-md-4 px-3 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5 text-white"
                            style={{ background: "var(--primary-color)", borderRadius: "10px", height: '48px' }}
                            onClick={onCheckout}
                        >
                            {loading ?
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                    <CircularProgress color="inherit" size={20} /> Loading
                                </div>
                                :
                                "Confirm Session"
                            }
                        </Button>
                    </div>
                    <AlertPopup showModal={showModal} setShowModal={setShowModal} data={modalData} />
                    <Dialog className="Confirmation--Dialog" open={showScreenerModal} onClose={handleCloseScreenerConfirmation} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title" className="justify-content-center d-flex">Did the Screener Open in a New Tab?</DialogTitle>
                        <DialogContent style={{ maxWidth: "600px", width: "100%", minWidth: "100%" }}>
                            <DialogContentText id="alert-dialog-description" className="justify-content-center d-flex">
                                If it has not opened in new tab, click the 'Open Link' button below to access it <br /><br />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={() => window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=backendstart_Screener_Intro&appURL=${encodeURI("https://us.unitedwecare.com")}`)} className="PrimaryCTA--Outlined flex-1">Open Link</button>
                            <button onClick={handleCloseScreenerConfirmation} className="PrimaryCTA flex-1">Confirm</button>
                        </DialogActions>
                    </Dialog>
                </div >
            }
        </>
    );
};

export default ExpertBookingPreview;
